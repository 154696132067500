/* Set the base font-size for desktop screens */
html {
    font-size: 22px;
    background-color: #FDF8F2;
}

/* Override font-size for mobile screens */
@media only screen and (max-width: 1023px) {
    html {
        font-size: 18px;
    }
}

body {
  /*background-color: #FDF8F2;*/
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Responsive images */
img {
    max-width: 100%;
    height: auto;
}

