/* Regular */
@font-face {
    font-family: 'Graebenbach';
    src: url('./assets/fonts/Graebenbach-Regular.eot');
    src: url('./assets/fonts/Graebenbach-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Graebenbach-Regular.woff') format('woff'),
    url('./assets/fonts/Graebenbach-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Bold */
@font-face {
    font-family: 'Graebenbach';
    src: url('./assets/fonts/Graebenbach-Bold.eot');
    src: url('./assets/fonts/Graebenbach-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Graebenbach-Bold.woff') format('woff'),
    url('./assets/fonts/Graebenbach-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
